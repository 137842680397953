import React from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import PlaybackControl from '@material-ui/core/Fab'
import { DragIndicator, PlayArrow, Pause, Settings } from '@material-ui/icons/'
import { SvgIcon } from '@material-ui/core'
import Loop from '../assets/Loop'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import { secondsToTimeString } from '../utilities/stringFormatters'
import Menu from '../Menu'

// slider for start and end handles
const RangeSlider = withStyles(theme => ({
  root: {
    marginTop: -theme.spacing(0.5),
  },
  mark: {
    backgroundColor: theme.palette.primary.dark,
    height: 8,
    width: 1,
    marginTop: 16,
  },
  markLabel: {
    marginTop: 16,
  },
  rail: {
    height: 4,
    backgroundColor: theme.palette.player.spanner.rail,
  },
  track: {
    height: 4,
    backgroundColor: theme.palette.player.spanner.rail,
  },
  thumb: {
    width: 24,
    height: 24,
    marginTop: -10,
    backgroundColor: theme.palette.player.spanner.handle,
    boxShadow: theme.shadows[1],
    '&:focus': {
      boxShadow: theme.shadows[1],
    },
    '&:hover, &$active': {
      boxShadow: theme.shadows[6],
    },

    '& .dragIcon': {
      color: theme.palette.player.spanner.handleIcon,
      fontSize: 16,
    },
  },
}))(Slider)

// slider for the scrubber
const CursorSlider = withStyles(theme => ({
  root: {
    position: 'absolute',
    marginTop: -theme.spacing(0.5),
  },
  thumb: {
    zIndex: 100,
    width: 40,
    height: 40,
    marginTop: -18,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    '&:focus': {
      boxShadow: theme.shadows[1],
    },
    '&:hover, &$active': {
      boxShadow: theme.shadows[6],
    },
  },
  track: {
    height: 4,
    backgroundColor: theme.palette.common.white,
  },
  rail: {
    opacity: 0,
  },
}))(Slider)

function ThumbComponent(props) {
  return (
    <span {...props}>
      <DragIndicator className="dragIcon" />
    </span>
  )
}

function TooltipComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  )
}

const TimeSliderWrapper = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}))(Paper)

const ElapsedTime = withStyles(theme => ({
  root: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight
  },
}))(Box)

const TotalTime = withStyles(theme => ({
  root: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight
  },
}))(Box)

const PlayerButton = withStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
    boxShadow: theme.shadows[1]
  },
}))(PlaybackControl)

const TimelineWrapper = ({ ...overrides }) => {
  const timelineConfig = {
    color: 'primary',
    disabled: false,
    fullWidth: false,
    size: 'large',
    variant: 'dark',
    ...overrides,
  }

  const variant = timelineConfig.variant
  const valueToTime = v => secondsToTimeString(v, timelineConfig.precision || 0)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)

  const handleSettingsClick = event => {
    console.log('clicked settings menu button')
    setMenuAnchorEl(event.currentTarget)
  }

  const handleSettingsClose = () => {
    setMenuAnchorEl(null)
  }

  return (
    <TimeSliderWrapper {...timelineConfig} elevation={timelineConfig.elevation} square className={variant === 'light' ? 'TimelineLight' : 'TimelineDark'}>
      <Box display="flex">
        <Box>
          <PlaybackControl className="PlayPause" onClick={timelineConfig.onPlayPauseClick}>
            {timelineConfig.playing ? <Pause /> : <PlayArrow />}
          </PlaybackControl>
          <PlayerButton
            size="small"
            onClick={timelineConfig.onLoopClick}
            className={timelineConfig.loop && "Enabled"}
            elevation={0}
          >
            <SvgIcon>
              <Loop />
            </SvgIcon>
          </PlayerButton>
        </Box>
        <Box display="flex" alignItems="center" marginLeft={2}>
          <ElapsedTime className="ElapsedTime">{timelineConfig.elapsedTime}</ElapsedTime>
          <TotalTime className="TotalTime" marginLeft={1}>/ {timelineConfig.totalTime}</TotalTime>
        </Box>
        <Box
          style={{ position: 'relative' }}
          flexGrow={1}
          alignItems="flex-end"
          marginLeft={3}
          marginTop={2}
        >
          <CursorSlider
            aria-labelledby="cursor-slider"
            min={timelineConfig.min}
            max={timelineConfig.max}
            onChange={timelineConfig.onCursorChange}
            onChangeCommitted={timelineConfig.onCursorMouseUp}
            onMouseDown={e => timelineConfig.onCursorMouseDown(e, timelineConfig.cursorValue)}
            step={timelineConfig.step}
            value={timelineConfig.cursorValue}
            valueLabelFormat={valueToTime}
            ValueLabelComponent={TooltipComponent}
            ThumbComponent={ThumbComponent}
          />
          <RangeSlider
            aria-labelledby="range-slider"
            marks={timelineConfig.marks}
            min={timelineConfig.min}
            max={timelineConfig.max}
            onChange={timelineConfig.onRangeChange}
            onChangeCommitted={timelineConfig.onRangeMouseUp}
            onMouseDown={e => timelineConfig.onRangeMouseDown(e, timelineConfig.rangeValue)}
            step={timelineConfig.step}
            value={timelineConfig.rangeValue}
            valueLabelFormat={valueToTime}
            ValueLabelComponent={TooltipComponent}
            ThumbComponent={ThumbComponent}
          />
        </Box>
        <Box display="flex" alignItems="center" marginLeft={3}>
          <PlayerButton size="small" onClick={handleSettingsClick}>
            <Settings />
          </PlayerButton>
          <Menu
            menuItems={timelineConfig.settingsMenuItems}
            anchorEl={menuAnchorEl}
            onClose={handleSettingsClose}
          />
        </Box>
      </Box>
    </TimeSliderWrapper>
  )
}

export default TimelineWrapper
