import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '../Button'

const ActionButtonWrapper = withStyles({
  root: {
    position: 'absolute',
    width: '100%',
    zIndex: 1000
  }
})(Box)

export default function FloatingCenterButton(props) {
  return (
    <ActionButtonWrapper display='flex' justifyContent='center' py={2}>
      <Button
        variant='contained'
        startIcon={props.startIcon}
        onClick={props.onClick}
      >
        {props.label}
      </Button>
    </ActionButtonWrapper>
  )
}
