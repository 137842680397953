import React from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ChevronLeft, ChevronRight, MoreVert } from '@material-ui/icons/'
import JumboCard from '../JumboCard'

const SidePanelWrapper = ({ children, ...overrides }) => {
  const sidePanelConfig = {
    anchor: 'left',
    open: false,
    width: 'medium',
    PaperProps: {
      elevation: 16
    },
    ...overrides,
  }

  const anchor = sidePanelConfig.anchor
  const width = sidePanelConfig.width
  const headerTitle = sidePanelConfig.title
  const panelActions = sidePanelConfig.panelActions
  const closeAction = sidePanelConfig.closeAction
  const menu = sidePanelConfig.menu
  const menuAction = sidePanelConfig.menuAction
  const modal = sidePanelConfig.modal

  if (modal) {
    sidePanelConfig.hideBackdrop = false
    sidePanelConfig.variant = 'temporary'
  } else {
    sidePanelConfig.hideBackdrop = true
    sidePanelConfig.variant = 'persistent'
  }


  const SidePanel = withStyles(theme => ({
    paper: {
      width: theme.shape.sidepanelWidth[width],
    },
  }))(Drawer)

  return (
    <SidePanel {...sidePanelConfig}>
      <JumboCard
        avatar={
          menu ? (
            <IconButton edge="start" onClick={menuAction}>
              <MoreVert />
            </IconButton>
          ) : (
            <React.Fragment />
          )
        }
        cardActions={panelActions}
        headerActions={
          <IconButton onClick={closeAction}>
            {anchor === 'left' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        }
        title={headerTitle}
      >
        {children}
      </JumboCard>
    </SidePanel>
  )
}

export default SidePanelWrapper
