import React from 'react'
import PropTypes from 'prop-types'
import MenuMaterial from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Radio from '@material-ui/core/Radio'

/**
 * An object that describes a menu item
 * @typedef {Object} MenuItem
 * @property {string=} value - Provide a value, if the MenuCollection is of type "radio" and the menu item is considered a selectable option
 * @property {string} label - Display label for the item
 * @property {string=} icon - Icon that will appear to the left of the label
 * @property {function(): void} action - Function that will execute on item click
 */

/**
 * An object container for the menu items
 * @typedef {Object} MenuCollection
 * @property {radio|none} type - Determines how the menu items are displayed. The menu item can be displayed as a clickable button or a radio option.
 * @property {string=} sectionLabel - The label for the particular section of menu items
 * @property {string=} selectedValue - The selected value when the menu section is of type "radio"
 * @property {MenuItem[]} items - An array of menu items
 */

/**
 * The Menu component should be used in any case where the user can select from multiple options/actions
 */
function Menu(props) {
  return (
    <MenuMaterial
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      {props.menuItems ? (
        props.menuItems.map((section, sectionIndex) => (
          <div key={section.id}>
            <List
              component='nav'
              aria-label={section.sectionLabel && section.sectionLabel}
              subheader={
                section.sectionLabel && (
                  <ListSubheader>{section.sectionLabel}</ListSubheader>
                )
              }
            >
              {section.items.map((item) =>
                section.type === 'radio' ? (
                  // display radio buttons
                  <ListItem
                    key={item.id}
                    button
                    onClick={() => item.action(section.id, item)}
                  >
                    <ListItemIcon>
                      <Radio
                        size='small'
                        value={item.value}
                        checked={section.selectedValue === item.value}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                ) : (
                  // display regular list item
                  <ListItem key={item.id} button onClick={item.action}>
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              )}
            </List>
            {sectionIndex < props.menuItems.length - 1 && <Divider />}
          </div>
        ))
      ) : (
        <div>There are no menu items.</div>
      )}
    </MenuMaterial>
  )
}

export default Menu

Menu.propTypes = {
  /** The element to which the menu will anchor to */
  anchorEl: PropTypes.object,
  /** Function that will trigger when the menu closes */
  onClose: PropTypes.func,
  /** An array of menu sections with menu items */
  menuItems: PropTypes.array
}
