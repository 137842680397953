import React from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import defaultTheme from './theme'

const ThemeProviderWrapper = ({ children, theme, ...overrides }) => (
  <ThemeProvider theme={theme || defaultTheme({ ...overrides })}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

export default ThemeProviderWrapper
