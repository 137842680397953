import _ from 'lodash'
import React from 'react'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'
import 'prismjs/components/prism-yaml'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/plugins/highlight-keywords/prism-highlight-keywords'
import { makeStyles, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { BookmarkBorder, Close } from '@material-ui/icons'
import Button from '../Button'
import SidePanel from '../Sidepanel'
import InstructionBar from '../InstructionBar'

const useStyles = makeStyles(theme => ({
  editor: {
    backgroundColor: '#424242',
    fontFamily: '"Inconsolata", monospace',
    fontSize: 16,
    caretColor: '#fff',
    height: '100%',
    '& .token.punctuation': {
      color: '#acb2be',
    },
    '& .token.keyword.keyword-name': {
      color: '#f92672',
    },
    '& pre': {
      color: '#a1c181',
    },
  },
  sidePanel: {
    flexDirection: 'row',
    backgroundColor: '#424242 !important',
    '& .MuiCardContent-root': {
      padding: '0 !important',
    },
    '& .MuiCardHeader-root': {
      backgroundColor: '#424242',
      color: '#fff',
    },
    '& .MuiCardActions-root': {
      backgroundColor: '#424242',
    },
    '& .MuiIconButton-label': {
      color: '#fff',
    },
    '& .MuiDrawer-paper': {
      height: '88.2%',
    },
    '& .MuiDrawer-paperAnchorLeft': {
      marginTop: '4.2rem',
    },
  },
  bookmark: {
    color: theme.palette.secondary.main,
  },
  button: {
    width: '30%',
  },
  closeButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: '#fff',
    zIndex: '1',
  },
  instructionBar: {
    bottom: '0',
    left: '0',
    width: '43% !important',
    zIndex: '1202',
    position: 'fixed',
  },
}))

const CodeEditor = props => {
  const { open, code, setCode, toggleEditor } = props
  const [codeEditor, setCodeEditor] = React.useState(code)
  const classes = useStyles()

  const debounce = React.useCallback(
    _.debounce(_searchVal => {
      setCode(_searchVal)
    }, 1000),
    []
  )
  React.useEffect(() => debounce(codeEditor), [codeEditor])

  return open ? (
    <Editor
      value={codeEditor}
      onValueChange={value => setCodeEditor(value)}
      highlight={code => Prism.highlight(code, Prism.languages.yaml, 'yaml')}
      padding={10}
      className={classes.editor}
      key="CodeEditor"
      onClick={() => toggleEditor(false)}
    />
  ) : (
    <Editor
      value={codeEditor}
      onValueChange={value => setCodeEditor(value)}
      highlight={code => Prism.highlight(code, Prism.languages.yaml, 'yaml')}
      padding={10}
      className={classes.editor}
      key="CodeEditor"
    />
  )
}

const RSLEditorWrapper = ({ ...overrides }) => {
  const rslEditorConfig = {
    anchor: 'left',
    open: 'false',
    width: 'medium',
    ...overrides,
  }

  const onSearchAction = rslEditorConfig.onSearch
  const onBookmarkAction = rslEditorConfig.onBookmark
  const openEditor = rslEditorConfig.openEditor
  const toggleEditor = rslEditorConfig.toggleEditor
  const openSidePanel = rslEditorConfig.openSidePanel
  const toggleSidePanel = rslEditorConfig.toggleSidePanel

  const [code, setCode] = React.useState(`scenario:\n`)
  const classes = useStyles()

  const closeCodeEditor = () => {
    toggleEditor(false)
    toggleSidePanel(false)
  }

  const CloseButton = withStyles(theme => ({
    root: {
      color: theme.palette.background.default,
    },
  }))(IconButton)

  if (openEditor) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ position: 'relative', marginTop: '1rem' }}>
          <CloseButton
            aria-label="close code editor"
            size="small"
            onClick={closeCodeEditor}
            className={classes.closeButton}
          >
            <Close />
          </CloseButton>
          <CodeEditor open="false" toggleEditor={toggleEditor} code={code} setCode={setCode} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem 0' }}>
          <Button color="primary" className={classes.button} onClick={onSearchAction}>
            Search
          </Button>
          <IconButton
            onClick={onBookmarkAction}
            style={{ fontSize: '0.875rem', textTransform: 'uppercase' }}
          >
            <BookmarkBorder className={classes.bookmark} /> Bookmark
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {openSidePanel && (
        <div>
          <SidePanel
            {...rslEditorConfig}
            closeAction={() => toggleSidePanel(false)}
            open={openSidePanel}
            title="RSL Search"
            className={classes.sidePanel}
            panelActions={
              <React.Fragment>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => toggleEditor(true)}
                >
                  Search
                </Button>
                <IconButton
                  onClick={onBookmarkAction}
                  style={{
                    color: '#fff',
                    fontSize: '0.875rem',
                    textTransform: 'uppercase',
                  }}
                >
                  <BookmarkBorder className={classes.bookmark} /> Bookmark
                </IconButton>
              </React.Fragment>
            }
          >
            <CodeEditor code={code} setCode={setCode} />
          </SidePanel>
          <InstructionBar
            className={classes.instructionBar}
            variant="dark"
            instructions={[
              {
                instruction: 'tips',
                shortcuts: ['Ctrl', 'T'],
              },
              {
                instruction: 'fill suggestions',
                shortcuts: ['TAB'],
              },
              {
                instruction: 'show intellisense',
                shortcuts: ['Ctrl', 'TAB'],
              },
            ]}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default RSLEditorWrapper
