import React from 'react'
import { TextField } from '@material-ui/core'

const TextInputWrapper = ({ ...overrides }) => {
  const textInputConfig = {
    color: 'primary',
    fullWidth: true,
    size: 'medium',
    variant: 'standard',
    ...overrides,
  }

  return <TextField {...textInputConfig} />
}

export default TextInputWrapper
