import React from 'react'
import { Button } from '@material-ui/core'

const ButtonWrapper = ({ children, ...overrides }) => {
  const buttonConfig = {
    color: 'primary',
    disabled: false,
    fullWidth: false,
    size: 'medium',
    variant: 'contained',
    ...overrides,
  }

  return <Button {...buttonConfig}>{children}</Button>
}

export default ButtonWrapper
