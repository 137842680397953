import React from 'react'
import { createTheme } from '@material-ui/core'
import { Close, Sort } from '@material-ui/icons/'
// eslint-disable-next-line import/extensions
import '@fontsource/roboto'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const ThemeRC = ({
  baseFontSize = 16,
  subtitle2FontSize = 14,
  borderRadius = 4,
  buttonFontWeight = 700,
  fontWeightBold = 700,
  fontWeightRegular = 400,
  stepNumberFontWeight = fontWeightBold,
  stepLabelFontWeight = fontWeightBold,
  tableHeaderFontWeight = 700,
  // color
  baselineBackground = '#ffffff',
  buttonBorderColor = 'rgba(0, 0, 0, 0.12)',
  buttonSecondaryHoverColor = '#F5F5F5',
  lightBackground = '#ffffff',
  darkBackground = '#000000',
  listContainerBackground = '#F5F5F5',
  primaryColor = '#2f2e2f',
  primaryColorDark = '#161616',
  secondaryColor = '#6cc04a',
  primaryColorLight = lightBackground,
  selectedColor = secondaryColor,
  // text
  primaryTextDark = 'rgba(255, 255, 255, 1)',
  secondaryTextDark = 'rgba(255, 255, 255, 0.5)',
  primaryTextLight = 'rgba(0, 0, 0, 0.87)',
  secondaryTextLight = 'rgba(0, 0, 0, 0.54)',
  // other
  shortcutKeyBorder = 'rgba(0, 0, 0, 0.08)',
  shortcutKeyBorderDark = 'rgba(255, 255, 255, 0.25)',
  chipBackground = '#eeeeee',
  // player specific
  playerDark = '#000000',
  playerLight = lightBackground,
  spannerColor = secondaryColor,
  spannerHandleIcon = primaryColorDark,
  // spacing
  spacing = 8,
}) =>
  createTheme({
    // defaults overrides
    props: {
      MuiAppBar: {
        color: 'default',
      },
      MuiInput: {
        color: 'secondary',
      },
      MuiTextField: {
        color: 'secondary',
        size: 'small',
      },
      MuiOutlinedInput: {
        color: 'secondary',
      },
      MuiChip: {
        deleteIcon: <Close />,
        size: 'small',
      },
      MuiDataGrid: {
        ColumnUnsortedIcon: <Sort />,
      },
      MuiListItem: {
        dense: true,
      },
      MuiRadio: {
        color: 'secondary',
      },
      MuiCheckbox: {
        color: 'secondary',
      },
    },
    shape: {
      borderRadius,
      sidepanelWidth: {
        // bring breakpoints
        small: '32%',
        medium: '43%',
        large: '50%',
      },
    },
    spacing,
    // palette
    palette: {
      primary: {
        main: primaryColor,
        dark: primaryColorDark,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: lightBackground,
      },
      collectionBackground: '#FFFFFF',
      text: {
        primaryDark: primaryTextDark,
        secondaryDark: secondaryTextDark,
      },
      shortcutKeyBorder,
      shortcutKeyBorderDark,
      // player styles
      player: {
        background: {
          light: playerLight,
          dark: playerDark,
        },
        play: {
          background: {
            light: lightBackground,
          },
          color: {
            light: primaryTextDark,
          },
        },
        spanner: {
          handle: spannerColor,
          handleIcon: spannerHandleIcon,
          rail: spannerColor,
        },
        settings: {
          background: {
            light: playerLight,
            dark: alpha(playerLight, 0.2),
          },
          color: {
            light: primaryColorDark,
            dark: primaryColorLight,
          },
        },
      },
    },
    // typography
    typography: {
      htmlFontSize: baseFontSize,
      fontWeightBold,
      subtitle1: {
        fontSize: 12,
      },
    },
    // other overrides
    overrides: {
      // baseline css override
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: baselineBackground,
          },
        },
      },
      MuiFab: {
        // name of the css prop from the API
        root: {
          // jss styles
          backgroundColor: primaryColorDark,
          color: lightBackground,
          '&:hover': {
            backgroundColor: darkBackground,
            '@media (hover: none)': {
              backgroundColor: darkBackground,
            },
          },
        },
      },
      MuiPaper: {
        root: {
          // Toolbar
          '&.Toolbar': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FCFCFC',
            width: '100%',
            padding: spacing * 2,
            '& .InputContainer': {
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            },
            '& .ToolbarButton': {
              border: `1px solid ${buttonBorderColor}`,
              backgroundColor: '#FFF',
              padding: 7,
            },
            '& .CloseButton': {
              position: 'absolute',
              right: spacing / 2,
            },
          },
          // Timeline
          '&.TimelineLight': {
            backgroundColor: playerLight,
            '& .ElapsedTime': {
              color: primaryTextLight,
            },
            '& .TotalTime': {
              color: secondaryTextLight,
            },
            '& .MuiFab-root': {
              backgroundColor: playerLight,
              color: primaryColorDark,
              '&.Enabled': {
                backgroundColor: secondaryColor,
              },
            },
            '& .PlayPause': {
              backgroundColor: primaryColorDark,
              color: lightBackground,
            },
          },
          '&.TimelineDark': {
            backgroundColor: playerDark,
            '& .ElapsedTime': {
              color: primaryTextDark,
            },
            '& .TotalTime': {
              color: secondaryTextDark,
            },
            '& .MuiFab-root': {
              backgroundColor: alpha(playerLight, 0.2),
              color: primaryColorLight,
              '&.Enabled': {
                backgroundColor: secondaryColor,
              },
            },
            '& .PlayPause': {
              backgroundColor: lightBackground,
              color: primaryColorDark,
            },
          },
        },
      },
      MuiIconButton: {
        root: {
          borderRadius,
        },
      },
      MuiDrawer: {
        paperAnchorLeft: {
          marginTop: spacing,
          borderTopRightRadius: borderRadius,
          '& .SidepanelHeader': {
            padding: 0,
            paddingLeft: 16,
          },
        },
        paperAnchorDockedLeft: {
          borderRight: 0,
        },
        paperAnchorRight: {
          marginTop: spacing,
          borderTopLeftRadius: borderRadius,
          '& .SidepanelHeader': {
            padding: 0,
          },
          '& .MuiCardHeader-root': {
            flexDirection: 'row-reverse',
          },
          '& .MuiCardHeader-content': {
            marginLeft: 0,
          },
        },
        paperAnchorDockedRight: {
          borderLeft: 0,
        },
        paper: {
          '& .MuiCard-root': {
            // Sidepanel card container classes
            '&.SidepanelContainer': {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              borderRadius: 0,
              '& .SidepanelHeader': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiCardHeader-avatar': {
                marginRight: 0,
              },
              '& .MuiCardHeader-title': {
                fontSize: subtitle2FontSize,
              },
              '& .SidepanelContent': {
                backgroundColor: listContainerBackground,
                flex: 1,
                overflowY: 'scroll',
              },
            },
          },
        },
      },
      MuiCard: {
        root: {
          marginBottom: spacing,
          '& .ExpandableCardContent': {
            paddingTop: 0,
          },
        },
      },
      MuiCardHeader: {
        root: {},
        title: {
          fontSize: baseFontSize,
          fontWeight: fontWeightBold,
        },
        action: {
          marginTop: 0,
          marginRight: 0,
          '& .MuiCardActions-root': {
            padding: 0,
          },
        },
        subheader: {
          fontSize: 12,
        },
      },
      MuiCardContent: {
        root: {},
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: lightBackground,
        },
      },
      MuiButton: {
        root: {
          fontWeight: buttonFontWeight,
          color: primaryColor,
        },
        contained: {
          backgroundColor: lightBackground,
          color: primaryColor,
        },
        containedSecondary: {
          color: primaryColor,
          backgroundColor: lightBackground,
          '&:hover': {
            backgroundColor: buttonSecondaryHoverColor,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: lightBackground,
            },
          },
          '& .MuiButton-startIcon': {
            color: secondaryColor,
          },
          '& .MuiButton-endIcon': {
            color: secondaryColor,
          },
        },
        outlined: {
          borderColor: buttonBorderColor,
        },
        outlinedPrimary: {
          borderColor: buttonBorderColor,
          '&:hover': {
            border: `1px solid ${primaryColor}`,
            backgroundColor: buttonSecondaryHoverColor,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '& .MuiButton-startIcon': {
            color: secondaryColor,
          },
          '& .MuiButton-endIcon': {
            color: secondaryColor,
          },
        },
        outlinedSecondary: {
          borderColor: buttonBorderColor,
          '&:hover': {
            border: `1px solid ${primaryColor}`,
            backgroundColor: buttonSecondaryHoverColor,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          color: primaryColor,
          '& .MuiButton-startIcon': {
            color: secondaryColor,
          },
          '& .MuiButton-endIcon': {
            color: secondaryColor,
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: buttonSecondaryHoverColor,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '& .MuiButton-startIcon': {
            color: secondaryColor,
          },
          '& .MuiButton-endIcon': {
            color: secondaryColor,
          },
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: buttonSecondaryHoverColor,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          color: primaryColor,
          '& .MuiButton-startIcon': {
            color: secondaryColor,
          },
          '& .MuiButton-endIcon': {
            color: secondaryColor,
          },
        },
      },
      MuiChip: {
        root: {
          marginRight: spacing / 2,
          backgroundColor: chipBackground,
          fontWeight: fontWeightRegular,
          textTransform: 'capitalize',
        },
      },
      MuiMenu: {
        paper: {
          minWidth: 180,
        },
      },
      MuiListSubheader: {
        root: {
          fontSize: '0.8rem',
          lineHeight: `${spacing * 5} + px`,
        },
      },
      MuiListItem: {
        gutters: {
          paddingRight: spacing * 6,
        },
      },
      MuiListItemIcon: {
        root: {
          color: '#BDBDBD',
          minWidth: spacing * 4,
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
          },
        },
      },
      MuiAutocomplete: {
        popper: {
          width: '100%',
        },
        root: {
          display: 'flex',
        },
        popperDisablePortal: {
          position: 'relative',
          width: '100%',
        },
        paper: {
          '&.OverlayPaper': {
            boxShadow: 'none',
            borderRadius: 0,
            margin: 0,
          },
        },
        listbox: {
          '&.OverlayListbox': {
            padding: 0,
            paddingTop: spacing / 2,
            marginTop: spacing * 2,
          },
        },
        tag: {
          margin: 2,
        },
      },
      MuiDataGrid: {
        root: {
          border: 0,

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: tableHeaderFontWeight,
          },
          '& .MuiDataGrid-columnHeaderSortable .MuiDataGrid-columnHeaderTitle': {
            color: primaryColor,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .ActionButton': {
            color: primaryColor,
          },
        },
      },
      MuiStepIcon: {
        root: {
          fontSize: '2.2rem',
          fontWeight: stepNumberFontWeight,
          '&.MuiStepIcon-active': {
            color: selectedColor,
          },
        },
      },
      MuiStepContent: {
        root: {
          marginLeft: spacing * 2 + 1,
          paddingLeft: spacing * 3 + 1,
        },
      },
      MuiStepConnector: {
        vertical: {
          marginLeft: spacing * 2 + 1,
        },
      },
      MuiStepLabel: {
        label: {
          '&.MuiStepLabel-active': {
            fontWeight: stepLabelFontWeight,
          },
        },
      },
    },
  })

export default ThemeRC
